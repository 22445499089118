<template>
  <div class="resolve">
    <Breadcrumb
      class="breadcrumb"
      :customBreadcrumbList="[
        { name: 'ToeflTestResults' },
        { text: 'Test Result', path: `/result?id=${$route.query.user_exam_id}` }
      ]"
    >
      <template slot="restPage">
        <template v-if="question_detail.chapter_title">
          <el-breadcrumb-item>
            {{ skillName }}
          </el-breadcrumb-item>
          <el-breadcrumb-item>
            {{ breadcrumbTitle }}
          </el-breadcrumb-item>
          <el-breadcrumb-item>
            {{ question_detail.chapter_title }}
          </el-breadcrumb-item>
          <el-breadcrumb-item>
            {{ `#${getQuestionOrder()}` }}
          </el-breadcrumb-item>
        </template>
      </template>
    </Breadcrumb>
    <template v-if="question_detail.skill_name == 'reading'">
      <ReadingM
        v-if="isPhone"
        :question_detail="question_detail"
        :question_tags="question_tags"
        :title="title"
        :lang="lang.value"
      ></ReadingM>
      <Reading
        v-else
        :question_detail="question_detail"
        :question_tags="question_tags"
        :title="title"
        :lang="lang.value"
      ></Reading>
    </template>

    <template v-if="question_detail.skill_name == 'listening'">
      <ListeningM
        v-if="isPhone"
        :question_detail="question_detail"
        :question_tags="question_tags"
        :title="title"
        :lang="lang.value"
      ></ListeningM>
      <Listening
        v-else
        :question_detail="question_detail"
        :question_tags="question_tags"
        :title="title"
        :lang="lang.value"
      ></Listening>
    </template>
    <template v-if="question_detail.skill_name == 'speaking'">
      <SpeakingM
        v-if="isPhone"
        :question_detail="question_detail"
        :question_tags="question_tags"
        :title="title"
        :lang="lang.value"
        :examInfo="exam_info"
      ></SpeakingM>
      <Speaking
        v-else
        :question_detail="question_detail"
        :question_tags="question_tags"
        :title="title"
        :lang="lang.value"
        :examInfo="exam_info"
      ></Speaking>
    </template>
    <template v-if="question_detail.skill_name == 'writing'">
      <WritingM
        v-if="isPhone"
        :question_detail="question_detail"
        :question_tags="question_tags"
        :title="title"
        :lang="lang.value"
        :examInfo="exam_info"
      ></WritingM>
      <Writing
        v-else
        :question_detail="question_detail"
        :question_tags="question_tags"
        :title="title"
        :lang="lang.value"
        :examInfo="exam_info"
      ></Writing>
    </template>
    <Lock
      ref="lock"
      :userExamId="user_exam_id"
      :examInfo="exam_info"
      :pointPackages="pointPackages"
    />
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import Reading from "./Reading";
import Listening from "./Listening";
import Speaking from "./Speaking";
import Writing from "./Writing";
import ReadingM from "./mobile/Reading";
import ListeningM from "./mobile/Listening";
import SpeakingM from "./mobile/Speaking";
import WritingM from "./mobile/Writing";
import TOEFL from "@/apis/toefl";
import Lock from "@/views/toefl/transcript/components/Lock";

export default {
  metaInfo() {
    return {
      title: "Explanation - " + this.title + " | TestAdmit"
    };
  },

  components: {
    Breadcrumb,
    Reading,
    Listening,
    Speaking,
    Writing,
    ReadingM,
    ListeningM,
    SpeakingM,
    WritingM,
    Lock
  },
  mixins: [],
  props: [],
  data() {
    return {
      question_detail: {},
      question_tags: [],
      title: "",
      breadcrumbTitle: "",
      skillName: "",
      exam_info: { student: { first_name: "", last_name: "" } },
      pointPackages: []
    };
  },
  computed: {
    ...mapState("user", ["lang"]),
    user_exam_id() {
      return this.$route.query.user_exam_id;
    },
    question_id() {
      return this.$route.query.question_id;
    },
    isPhone() {
      let isPhone = false;
      if (document.body.clientWidth <= 768) {
        isPhone = true;
      }
      return isPhone;
    }
  },
  watch: {},

  mounted() {
    this.getResolve();
    this.getTranscript();
    this.getPointPackage();
  },

  methods: {
    getAlert() {
      this.$refs.lock.getAlert();
      this.$message("Please wait patiently for the teacher to grade it!Thanks♪(･ω･)ﾉ");
    },
    async getTranscript() {
      const res = await TOEFL.getTranscript(this.user_exam_id);
      this.exam_info = res.exam_info;
    },
    async getPointPackage() {
      const res = await TOEFL.getPointPackage(this.user_exam_id);
      if (res.point_packages.length > 0) {
        res.point_packages.forEach(val => {
          val["selected"] = false;
        });
        this.pointPackages = res.point_packages;
      }
    },
    async getResolve() {
      const res = await TOEFL.getResolve(this.user_exam_id, {
        toefl_question_id: this.question_id
      });
      this.question_tags = res.question_tags;
      this.question_detail = res.question;
      this.skillName =
        res.question.skill_name.charAt(0).toUpperCase() +
        res.question.skill_name.slice(1);
      this.title =
        res.exam.title +
        " - " +
        this.skillName +
        " - " +
        res.question.chapter_title +
        " - #" +
        res.question.question_order;
      this.breadcrumbTitle = res.exam.title;
    },
    viewResolve(question_id) {
      this.routerPush({
        name: "ToeflResolve",
        query: {
          user_exam_id: this.user_exam_id,
          question_id
        }
      });
    },
    titleCase(str) {
      let newStr = str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase();
      return newStr;
    },
    getQuestionOrder() {
      if (["speaking", "writing"].includes(this.question_detail.skill_name)) {
        return this.question_detail.chapter_order;
      }
      return this.question_detail.question_order;
    }
  }
};
</script>

<style scoped>
.resolve {
  background: white;
  position: absolute;
  top: 0;
  bottom: 0;
  padding-top: 100px;
  width: 100%;
}
.cover {
  padding: 0 15px 15px;
}

.breadcrumb {
  margin: 20px 15px 20px 15px;
}
@media screen and (max-width: 768px) {
  .resolve {
    padding-top: 60px;
  }
  .breadcrumb {
    margin: 15px;
  }
}
</style>
